import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import useApiHook from "hooks/useApiHook";
import { toast } from "react-toastify";
import PrimaryBtn from "components/btns/PrimaryBtn";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

import FbAuthButton from "helper/FbAuthButton";
import AppleLoginButton from "helper/AppleLoginButton";
import { toggleLogin } from "redux/auth/authSlice";
import jwtDecode from "jwt-decode";
import AuthModel from "modals/common/authModel";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "First Name is too short.")
    .max(50, "First Name is too long.")
    .matches(/^[a-zA-Z\s]*$/, "First Name containt letters only")
    .required("First Name is required")
    .trim(),
  lastName: Yup.string()
    .min(3, "Last Name is too short.")
    .max(50, "Last Name is too long.")
    .matches(/^[a-zA-Z\s]*$/, "Last Name containt letters only")
    .trim(),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email is required")
    .trim(),
  phone: Yup.string().required("Phone number is requred").trim(),
  password: Yup.string()
    .min(8, "Minimum 8 characters are required.")
    .max(16, "Maximum 16 character are required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,16}$/,
      "Password must contain at least one number, one uppercase letter, one lowercase letter and one special character!"
    )
    .required("Password is required")
    .trim(),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .trim()
    .oneOf(
      [Yup.ref("password")],
      "Confirm password need to be the same as password"
    ),
});

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phone: "",
  confirmPassword: "",
};

const SignUp = () => {
  const { auth } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleApiCall, isApiLoading } = useApiHook();
  const [userEmail, setUserEmail] = useState("");
  const [isTypePasssword, setIsTypePasssword] = useState(true);
  const [isTypeConfirmPasssword, setIsTypeConfirmPasssword] = useState(true);
  const [isVerification, setIsVerification] = useState(false);
  const [isCheckBox, setIsCheckBox] = useState(false);

  const [appleFormValues, setAppleFormValues] = useState({
    isAppleModal: false,
    isNameRequired: false,
    isPhoneRequired: false,
    name: "",
    phone: "",
    appleUserId: "",
    authorization: {
      appleRefreshToken: "",
      id_token: "",
    },
    registerMethod: "apple",
    platform: "web",
  });
  const [fbFormValues, setFBFormValues] = useState({
    isFbEmail: false,
    email: "",
    name: "",
    profileImage: "",
    fbUserId: "",
    registerMethod: "facebook",
    platform: "web",
  });

  const handleSignUp = async (values) => {
    if (!isPossiblePhoneNumber(values?.phone))
      return toast.error("Invalid phone number");
    if (!isCheckBox) 
      return toast.error("Please accept terms & conditions");
    setUserEmail(values?.email);
    const result = await handleApiCall({
      method: "post",
      url: "/register",
      data: {
        ...values,
        name: `${values?.firstName} ${values?.lastName}`,
        registerMethod: "email",
        platform: "web",
      },
      headers: { Authorization: "none" },
    });
    if (result.status === 200) {
      toast.success(result?.data?.message);
      setIsVerification(true);
    }
  };

  const handleResendEmail = async () => {
    const result = await handleApiCall({
      method: "post",
      url: "/resend-verify",
      data: { email: userEmail },
      headers: { Authorization: "none" },
    });
    if (result.status === 200) toast.success(result?.data?.message);
  };

  useEffect(() => {
    if (auth?.isLogin) navigate("/home");
  }, [auth?.isLogin]);

  const handleFacebookResponse = (values) => {
    let info = {
      email: values?.data?.email,
      name: values?.data?.name
        ? values?.data?.name
        : values?.data?.first_name && values?.data?.last_name
        ? `${values?.data?.first_name} ${values?.data?.last_name}`
        : values?.data?.first_name,
      profileImage: values?.data?.picture?.data?.url,
      fbUserId: values?.data?.userID,
      registerMethod: "facebook",
      platform: "web",
    };
    if (info?.fbUserId && info?.name) return handleSocialSignIn(info);
  };

  const handleSocialSignIn = async (values) => {
    const result = await handleApiCall({
      method: "post",
      url: "/social-login",
      data: values,
      headers: { Authorization: "none" },
    });
    if (result.status === 203) {
      toast.error(result?.data?.error);
      setFBFormValues({
        isFbEmail: true,
        isEmailRequired: result?.data?.isEmailRequired,
        isPhoneRequired: result?.data?.isPhoneRequired,
        email: result?.data?.email,
        phone: result?.data?.phone,
        name: result?.data?.name,
        profileImage: result?.data?.profileImage,
        fbUserId: result?.data?.fbUserId,
        registerMethod: result?.data?.registerMethod,
        platform: "web",
      });
    }
    if (result.status === 200) {
      dispatch(
        toggleLogin({
          isLogin: true,
          userInfo: result?.data,
        })
      );
      toast.success("Login successful");
      navigate("/home");
    }
  };

  const appleLoginSuccess = async (response) => {
    if (response.error) return;
    const result = await handleApiCall({
      method: "post",
      url: "/apple/apple-signin",
      data: {
        ...response,
        name:
          response?.user?.name?.firstName || response?.user?.name?.lastName
            ? `${response?.user?.name?.firstName} ${response?.user?.name?.lastName}`
            : null,
        platform: "web",
        registerMethod: "apple",
      },
      headers: { Authorization: "none" },
    });
    if (result?.status === 203) {
      toast.error(result?.data?.error);
      setAppleFormValues({
        ...appleFormValues,
        isAppleModal: true,
        isNameRequired: result?.data?.isNameRequired,
        isPhoneRequired: result?.data?.isPhoneRequired,
        email: result?.data?.email || "",
        name: result?.data?.name || "",
        phone: result?.data?.phone || "",
        appleUserId: result?.data?.appleUserId || "",
        authorization: {
          appleRefreshToken: result?.data?.appleRefreshToken || "",
          id_token: result?.data?.token || "",
        },
      });
    }
    if (result?.status === 200) {
      dispatch(
        toggleLogin({
          isLogin: true,
          userInfo: result?.data,
        })
      );
      toast.success("Login successful");
      navigate("/home");
    }
  };

  const handleGoogleResponse = (values) => {
    let decode = jwtDecode(values?.credential);
    return handleSocialSignIn({
      email: decode?.email,
      name: decode?.name,
      profileImage: decode?.picture,
      registerMethod: "google",
      platform: "web",
    });
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleGoogleResponse,
    });
    google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
      theme: "outline",
      size: "large",
    });
    if (!auth?.isLogin && !isVerification) google.accounts.id.prompt();
  }, []);

  return (
    <div className="w-full min-h-screen bg-ezMidWhite">
      <div className="flex flex-col   lg:grid  lg:grid-cols-2 px-4 lg:px-0 items-center w-full h-full">
        <div className="h-auto w-full items-center py-10 flex flex-col justify-center lg:hidden">
          <img
            className="h-10 sm:h-14 w-auto"
            src="/assets/images/ezvoltzLogo.png"
            alt="Your Company"
          />
        </div>
        <div className="ez__AuthForm  px-4 xl:px-10">
          <div className="title w-full flex justify-center flex-col">
            {!isVerification && (
              <>
                <h3 className="text-2xl font-semibold mb-2 text-ezBlack">
                  Create your account
                </h3>
                <p className="text-base mb-4 text-ezNuturalGray">
                  Create your account by entering the details.
                </p>
                <div className="w-full flex justify-center mb-2">
                  <div id="buttonDiv" />
                </div>
                <div className="grid grid-cols-2 my-2 gap-3">
                  <FbAuthButton
                    handleFacebookResponse={handleFacebookResponse}
                    text="Signin with Facebook"
                  />
                  <AppleLoginButton appleLoginSuccess={appleLoginSuccess} />
                </div>
                <span className="block w-full ez__FormLine text-base text-ezDarkGray100 text-center relative my-1">
                  OR
                </span>
              </>
            )}
            <>
              <Formik
                initialValues={initialState}
                validationSchema={SignupSchema}
                onSubmit={handleSignUp}
              >
                {({ errors, values, setFieldValue, resetForm }) => (
                  <>
                    <Form className="ez__Form w-full">
                      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-y-2 md:gap-x-3">
                        <div className="m-0 p-0">
                          <span className="text-sm text-ezNuturalGray">
                            FIRST NAME
                          </span>
                          <Field
                            type="text"
                            name="firstName"
                            placeholder="Enter your first name"
                            className="w-full block border border-ezMidGray rounded-lg text-base text-ezBlack p-2 mb-2"
                          />
                          <ErrorMessage
                            name="firstName"
                            render={(msg) => (
                              <p className="text-xs text-ezRed block -mt-1">
                                {msg}
                              </p>
                            )}
                          />
                        </div>

                        <div className="m-0 p-0">
                          <span className="text-sm text-ezNuturalGray">
                            LAST NAME{" "}
                            <span className="text-xs">(optional)</span>
                          </span>
                          <Field
                            type="text"
                            name="lastName"
                            placeholder="Enter your last name"
                            className="w-full block border border-ezMidGray rounded-lg text-base text-ezBlack p-2 mb-2"
                          />
                          <ErrorMessage
                            name="lastName"
                            render={(msg) => (
                              <p className="text-xs text-ezRed block -mt-1">
                                {msg}
                              </p>
                            )}
                          />
                        </div>

                        <div className="m-0 p-0">
                          <span className="text-sm text-ezNuturalGray">
                            EMAIL
                          </span>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Enter a valid email"
                            className="w-full block border border-ezMidGray rounded-lg text-base text-ezBlack p-2 mb-2"
                          />
                          <ErrorMessage
                            name="email"
                            render={(msg) => (
                              <p className="text-xs text-ezRed block -mt-1">
                                {msg}
                              </p>
                            )}
                          />
                        </div>

                        <div className="m-0 p-0">
                          <span className="text-sm text-ezNuturalGray">
                            PHONE NUMBER
                          </span>
                          <div className="w-full block border border-ezMidGray bg-white rounded-lg text-sm text-ezBlack p-2 ">
                            <PhoneInput
                              placeholder="Enter phone number"
                              value={values?.phone}
                              onChange={(value) =>
                                setFieldValue("phone", value)
                              }
                              country="US"
                              international={false}
                              withCountryCallingCode
                              defaultCountry="US"
                              initialValueFormat="+1"
                              countries={["US"]}
                              rules={{
                                required: true,
                                validate: isPossiblePhoneNumber,
                              }}
                            />
                          </div>
                          <div>
                            {errors?.phone && (
                              <p className="text-ezRed text-xs">
                                {errors?.phone}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="m-0 p-0">
                          <span className="text-sm text-ezNuturalGray">
                            PASSWORD
                          </span>
                          <div className="relative w-full">
                            <Field
                              type={isTypePasssword ? "password" : "text"}
                              name="password"
                              placeholder="Enter a strong password"
                              className="w-full block border border-ezMidGray rounded-lg text-base text-ezBlack p-2 mb-2"
                            />
                            <button
                              type="button"
                              onClick={() =>
                                setIsTypePasssword(!isTypePasssword)
                              }
                              className="absolute top-3 right-4 z-50 w-max text-ezBlack hover:text-ezGreen"
                            >
                              {isTypePasssword ? (
                                <BsFillEyeSlashFill
                                  className="w-5 h-5"
                                  color="#D3D3D3"
                                />
                              ) : (
                                <BsFillEyeFill
                                  className="w-5 h-5 "
                                  color="#D3D3D3"
                                />
                              )}
                            </button>
                          </div>
                          <ErrorMessage
                            name="password"
                            render={(msg) => (
                              <p className="text-xs text-ezRed block -mt-1">
                                {msg}
                              </p>
                            )}
                          />
                        </div>

                        <div className="m-0 p-0">
                          <span className="text-sm text-ezNuturalGray">
                            CONFIRM PASSWORD
                          </span>
                          <div className="relative w-full">
                            <Field
                              type={
                                isTypeConfirmPasssword ? "password" : "text"
                              }
                              name="confirmPassword"
                              placeholder="Confirm your password"
                              className="w-full block border border-ezMidGray rounded-lg text-base text-ezBlack p-2 mb-2"
                            />
                            <button
                              type="button"
                              onClick={() =>
                                setIsTypeConfirmPasssword(
                                  !isTypeConfirmPasssword
                                )
                              }
                              className="absolute top-3 right-4 z-50 w-max text-ezBlack hover:text-ezGreen"
                            >
                              {isTypeConfirmPasssword ? (
                                <BsFillEyeSlashFill
                                  className="w-5 h-5"
                                  color="#D3D3D3"
                                />
                              ) : (
                                <BsFillEyeFill
                                  className="w-5 h-5 "
                                  color="#D3D3D3"
                                />
                              )}
                            </button>
                          </div>
                          <ErrorMessage
                            name="confirmPassword"
                            render={(msg) => (
                              <p className="text-xs text-ezRed block mb-2 -mt-1">
                                {msg}
                              </p>
                            )}
                          />
                        </div>
                      </div>

                      <div className="m-0 p-0 mt-2">
                        <PrimaryBtn
                          btnType="submit"
                          isApiLoading={isApiLoading}
                          text="Create an Account"
                        />
                        <div className="flex items-center justify-center mb-2">
                          <input
                            type="checkbox"
                            name="chargres"
                            id="allChargers"
                            value="allChargers"
                            checked={isCheckBox}
                            className="w-4 h-4"
                            onChange={(e) => setIsCheckBox(!isCheckBox)}
                          />
                          <label
                            htmlFor="allChargers"
                            className="cursor-pointer ml-2 text-ezBlack"
                          >
                            I accept all the terms & conditions
                          </label>
                        </div>
                      </div>
                    </Form>

                    <AuthModel
                      isModal={isVerification}
                      handleCloseModal={() => {
                        setIsVerification(false);
                        resetForm();
                      }}
                      userEmail={userEmail}
                      title="Account Successfully Created"
                      descriptionBefore="A Verification email has been sent to"
                      descriptionAfter="Please check your inbox and verify your email. "
                      handleResendEmail={handleResendEmail}
                      resendTextBefore="If email is not received yet,"
                      resendTextAfter="to resend email."
                    />
                  </>
                )}
              </Formik>

              <p className="text-base text-ezBlack text-center flex items-center justify-center mb-3">
                Already have an account?
                <Link
                  to="/login"
                  className="block sm:block text-ezGreen font-semibold hover:text-ezGreen ml-1"
                >
                  Log In
                </Link>
              </p>
            </>
          </div>
        </div>
        <div className="h-screen w-full hidden lg:block">
          <img
            src="/assets/images/login.png"
            alt="Login"
            className="w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
